import type {ReactNode} from 'react';
import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useCreateLeadTokenMutation} from '@/mutations/lead-token';
import {useLeadTokenQuery} from '@/queries/lead-token';
import type {LeadToken} from '@/types/lead-token';

export type LeadTokenStateType = {
    leadToken : LeadToken | null;
};
const LeadTokenContext = createContext<LeadTokenStateType>({leadToken: null});

export const LeadTokenContextProvider
    = ({children} : {children : ReactNode}) : ReactNode => {
        const [searchParams] = useSearchParams();
        const generateLeadTokenMutation = useCreateLeadTokenMutation();
        const handleMutation = useHandleMutation();
        const navigate = useNavigate();
        const {pathname} = useLocation();

        const [leadToken, setLeadToken] = useState<LeadToken | null>(null);
        let currentLt = leadToken;

        const leadTokenId = useMemo(() => {
            return pathname.split('/').slice(-1)[0];
        }, [pathname]);

        const leadTokenQuery = useLeadTokenQuery(leadTokenId);

        if (leadTokenQuery.isError) {
            throw new Error('token not found');
        }

        useEffect(() => {
            if (leadTokenQuery.data) {
                setLeadToken(leadTokenQuery.data);
                currentLt = leadTokenQuery.data;
            }
        }, [leadTokenQuery.data]);

        useEffect(() => {
            const generateToken = async () => {
                const sourceId = searchParams.get('utm_source_id');
                const pageId = searchParams.get('utm_page_id');

                const mutationResult = await handleMutation(generateLeadTokenMutation, {sourceId, pageId});

                if (mutationResult.success) {
                    setLeadToken(mutationResult.data);
                    currentLt = mutationResult.data;
                    navigate(pathname !== '/' ? `${pathname}/${mutationResult.data.id}` : `/${mutationResult.data.id}`);
                }
            };

            if (!leadTokenId && !leadToken && !leadTokenQuery.data) {
                generateToken().catch(console.error);
            }
        }, []);

        return <LeadTokenContext.Provider value={{leadToken: currentLt}}>{children}</LeadTokenContext.Provider>;
    };

export const useLeadTokenContext = () : LeadTokenStateType => {
    const context = useContext(LeadTokenContext);

    if (Object.keys(context).length === 0) {
        throw new Error('useLeadTokenContext must be used within a LeadTokenContextProvider');
    }

    return context;
};
