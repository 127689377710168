import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import {mapRawToOffer} from '@/mappers/offer';
import type {JsonApiDocument} from '@/types/json-api';
import type {Offer, RawOffer} from '@/types/offer';
import {apiUrl} from '@/utils/api';

export class LapFetchError extends Error {}

export const useOfferQuery = (pageId : string | null) : UseQueryResult<Offer> => {
    return useQuery({
        enabled: !!pageId,
        queryKey: ['offer', pageId],
        queryFn: async ({signal}) => {
            const response = await window.fetch(apiUrl(`/offer/by-page-id/${pageId}`).toString(), {
                signal,
            });

            if (!response.ok) {
                throw new LapFetchError(`Unable to fetch active offer for pageId ${pageId}`);
            }

            const raw = await response.json() as JsonApiDocument<RawOffer>;
            return mapRawToOffer(raw.data);
        },
    });
};
