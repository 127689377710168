import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import type {JsonApiDocument} from '@/types/json-api';
import type {Lap} from '@/types/lap';
import {apiUrl} from '@/utils/api';

export class LapFetchError extends Error {}

export const useLapQuery = (pageId : string | null) : UseQueryResult<Lap> => {
    return useQuery({
        enabled: !!pageId,
        queryKey: ['lap', pageId],
        queryFn: async ({signal}) => {
            const response = await window.fetch(apiUrl(`/lap/by-page-id/${pageId}`).toString(), {
                signal,
            });

            if (!response.ok) {
                throw new LapFetchError(`Unable to fetch active offer for pageId ${pageId}`);
            }

            const raw = await response.json() as JsonApiDocument<Lap>;
            return raw.data;
        },
    });
};
