import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow} from '@mui/material';
import styled from '@mui/material/styles/styled';
import type React from 'react';

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#d3ecc0',
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    textAlign: 'center',
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const FibrexTable = () : React.ReactElement => {
    return <Table>
        <TableHead>
            <TableRow>
                <StyledTableCell>&nbsp;</StyledTableCell>
                <StyledTableCell>Fibrex:</StyledTableCell>
                <StyledTableCell>Wood:</StyledTableCell>
                <StyledTableCell>Vinyl:</StyledTableCell>
                <StyledTableCell>Aluminum:</StyledTableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <StyledTableRow>
                <StyledTableCell>Rot Resistant</StyledTableCell>
                <StyledTableCell>
                    <CheckCircleIcon fontSize={'large'} sx={{color: '#6CC14C'}}/>
                </StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
                <StyledTableCell><CheckCircleOutlineIcon fontSize={'large'}/></StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell>Endures Extreme Heat</StyledTableCell>
                <StyledTableCell>
                    <CheckCircleIcon fontSize={'large'} sx={{color: '#6CC14C'}}/>
                </StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell>Made-To-Last Durability</StyledTableCell>
                <StyledTableCell>
                    <CheckCircleIcon fontSize={'large'} sx={{color: '#6CC14C'}}/>
                </StyledTableCell>
                <StyledTableCell><CheckCircleOutlineIcon fontSize={'large'}/></StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
                <StyledTableCell><CheckCircleOutlineIcon fontSize={'large'}/></StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell>Insulation Performance</StyledTableCell>
                <StyledTableCell>
                    <CheckCircleIcon fontSize={'large'} sx={{color: '#6CC14C'}}/>
                </StyledTableCell>
                <StyledTableCell><CheckCircleOutlineIcon fontSize={'large'}/></StyledTableCell>
                <StyledTableCell><CheckCircleOutlineIcon fontSize={'large'}/></StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell>Low Maintenance Upkeep</StyledTableCell>
                <StyledTableCell>
                    <CheckCircleIcon fontSize={'large'} sx={{color: '#6CC14C'}}/>
                </StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
                <StyledTableCell><CheckCircleOutlineIcon fontSize={'large'}/></StyledTableCell>
                <StyledTableCell><CheckCircleOutlineIcon fontSize={'large'}/></StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell>Installation Included</StyledTableCell>
                <StyledTableCell>
                    <CheckCircleIcon fontSize={'large'} sx={{color: '#6CC14C'}}/>
                </StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
            </StyledTableRow>
        </TableBody>
    </Table>;
};

export default FibrexTable;
