import type {RefObject} from 'react';
import {useEffect, useMemo, useState} from 'react';

const useOnScreen = (ref : RefObject<HTMLElement>) : boolean => {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setIntersecting(entry.isIntersecting);
        },
        {
            root: ref.current,
            threshold: .25,
        }
    ), [ref]);

    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return isIntersecting;
};

export default useOnScreen;
