import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Container, Radio, useTheme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useRef, useState} from 'react';
import Slider from 'react-slick';
import Discover02 from '../assets/discover-pic-02.jpg';
import Discover04 from '../assets/discover-pic-04.png';
import Discover05 from '../assets/discover-pic-05.jpg';
import Discover06 from '../assets/discover-pic-06.jpg';
import Discover07 from '../assets/discover-pic-07.jpg';
import Discover10 from '../assets/discover-pic-10.jpg';
import Discover11 from '../assets/discover-pic-11.jpg';
import Discover13 from '../assets/discover-pic-13.jpg';
import Discover14 from '../assets/discover-pic-14.jpg';
import Discover15 from '../assets/discover-pic-15.jpg';
import Discover18 from '../assets/discover-pic-18.jpg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CarouselButton from '@/components/CarouselButton';
import useOnScreen from '@/hooks/useOnScreen';

// @TODO get approved alt strings
const items = [
    {image: Discover04, alt: 'Carousel Image #4'},
    {image: Discover02, alt: 'Carousel Image #2'},
    {image: Discover05, alt: 'Carousel Image #5'},
    {image: Discover06, alt: 'Carousel Image #6'},
    {image: Discover07, alt: 'Carousel Image #7'},
    {image: Discover10, alt: 'Carousel Image #10'},
    {image: Discover11, alt: 'Carousel Image #11'},
    {image: Discover13, alt: 'Carousel Image #13'},
    {image: Discover14, alt: 'Carousel Image #14'},
    {image: Discover15, alt: 'Carousel Image #15'},
    {image: Discover18, alt: 'Carousel Image #18'},
];

type ItemProps = {
    itemNumber : number;
};

const Item = ({itemNumber} : ItemProps) => {
    return <Box
        component='img'
        src={items[itemNumber].image}
        alt={items[itemNumber].alt}
        key={`carousel-item-${itemNumber}`}
        sx={{
            p: 1,
            height: '100%',
            width: '100%',
            objectFit: 'contain',
        }}
    >
    </Box>;
};

const DiscoverCarousel = () : React.ReactElement => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const sliderRef = useRef<Slider>(null);
    const boxRef = useRef<HTMLElement>(null);
    const isVisible = useOnScreen(boxRef);
    const [checkedDot, setCheckedDot] = useState(0);
    const [settings, setSettings] = useState({
        centerMode: true,
        infinite: true,
        centerPadding: '20%',
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        dots: !mobile,
        arrows: false,
        beforeChange: (prev : number, next : number) => {
            setCheckedDot(next);
        },
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        customPaging: (i : number) => <a><Radio key={`dot-${i}`} value={i} checked={checkedDot === i}/></a>,
    });

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                if (sliderRef.current) {
                    sliderRef.current.slickPlay();
                }
            }, 3500);
        }
    }, [isVisible]);

    useEffect(() => {
        if (sliderRef.current) {
            setSettings({
                ...settings,
                customPaging: (i : number) => {
                    return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a>
                            <Radio key={`dot-${i}`} value={i} checked={checkedDot === i}/>
                        </a>
                    );
                },
            });
        }
    }, [checkedDot]);

    return (
        <Container
            maxWidth={false}
            sx={{
                mb: 5,
                pb: 5,
                position: 'relative',
            }}
        >
            <Box ref={boxRef}>
                <Slider {...settings} ref={sliderRef}>
                    {
                        items.map((item, i) => <Item key={i} itemNumber={i}/>)
                    }
                </Slider>
            </Box>
            {!mobile && <><CarouselButton
                onClick={() => {
                    if (sliderRef.current) {
                        sliderRef.current.slickPrev();
                    }
                }}
                sx={{
                    top: 'calc(50% - 20px - 20px)',
                    left: {xs: '30px', sm: '60px'},
                }}
            >
                <ArrowBackIcon sx={{color: 'green'}}/>
            </CarouselButton>
            <CarouselButton
                onClick={() => {
                    if (sliderRef.current) {
                        sliderRef.current.slickNext();
                    }
                }}
                sx={{
                    top: 'calc(50% - 20px - 20px)',
                    right: {xs: '30px', sm: '60px'},
                }}
            >
                <ArrowForwardIcon sx={{color: 'green'}}/>
            </CarouselButton></>}
        </Container>
    );
};

export default DiscoverCarousel;
