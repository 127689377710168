import {ZonedDateTime} from '@js-joda/core';
import type {LeadToken, RawLeadToken} from '@/types/lead-token';

export const mapRawToLeadToken = (raw : RawLeadToken) : LeadToken => {
    return {
        ...raw,
        createdAt: ZonedDateTime.parse(raw.createdAt),
        updatedAt: raw.updatedAt ? ZonedDateTime.parse(raw.updatedAt) : null,
    };
};
