import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation} from '@tanstack/react-query';
import {apiUrl} from '@/utils/api';

export const useInvalidZipMutation = () : UseMutationResult<void, Error, string, never> => {
    return useMutation({
        mutationFn: async (leadTokenId : string) => {
            const response = await window.fetch(apiUrl('/hive/invalid-zip').toString(), {
                method: 'POST',
                body: JSON.stringify({leadTokenId: leadTokenId}),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('invalid-zip SideCar failure');
            }
        },
    });
};
