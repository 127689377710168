import {Box, Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';

const NotFound = () : JSX.Element => {
    const navigate = useNavigate();
    return (
        <>
            <Box sx={{textAlign: 'center', my: 10}}>
                <>
                    <Typography variant="h3" marginBottom={2}>404 - Page Not Found</Typography>
                    <Typography>
                    Unfortunately, the page you’re looking for does not exist or there was an error in the link you
                    followed.
                    </Typography>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Button sx={{margin: 2}} variant={'contained'} onClick={() => {
                            navigate('/');
                        }}>Go to homepage</Button></Box>
                </>
            </Box>
        </>
    );
};

export default NotFound;
