import type {StyledComponentProps} from '@mui/material';
import {Box, useTheme} from '@mui/material';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useRef} from 'react';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';

const StyledWhyRbaBox = styled(Box)(({sx} : {sx ?: StyledComponentProps}) => ({
    border: '1px solid #fff',
    borderRadius: 15,
    backgroundColor: '#fbfaf7',
    padding: 25,
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    minHeight: 228,
    ...sx,
}));

const StyledStepNumber = styled(Typography)(() => ({
    color: '#408740',
    fontFamily: 'Roboto',
    fontSize: 40,
    fontStyle: 'normal',
    fontWeight: 700,
}));

const WhyRbaSection = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('lg'));

    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'our_process'});
        }
    }, [isSectionVisible]);

    return <Grid container spacing={3} xs={12} sx={{justifyContent: 'center', textAlign: 'center', my: 5}}>
        <Grid xs={12} md={6} mdOffset={1}>
            <Typography variant={'h3'}>4 More Reasons Why Customers Love Renewal by Andersen</Typography>
            <Typography variant={'body1'} sx={{px: mobile ? 2 : 20, py: 2}}>
                Our <b>Signature Service Promise</b> is committed to giving you the best customer experience possible,
                including:
            </Typography>
        </Grid>
        <Grid xs={12} md={5} mdOffset={1}>
            <StyledWhyRbaBox>
                <Box sx={{px: 5, pt: 2}}>
                    <StyledStepNumber>01</StyledStepNumber>
                </Box>
                <Box sx={{p: 2}}>
                    <Typography variant={'h5'} sx={{mb: 2}}>Energy Efficiency</Typography>
                    <Typography variant={'body1'}>
                        Renewal by Andersen’s energy efficient windows are ENERGY STAR® certified and can reduce
                        heating and cooling costs.
                    </Typography>
                </Box>
            </StyledWhyRbaBox>
        </Grid>
        <Grid xs={12} md={5}>
            <StyledWhyRbaBox>
                <Box sx={{px: 5, pt: 2}}>
                    <StyledStepNumber>02</StyledStepNumber>
                </Box>
                <Box sx={{p: 2}}>
                    <Typography variant={'h5'} sx={{mb: 2}}>One-Stop Shop</Typography>
                    <Typography variant={'body1'}>
                        Renewal by Andersen eliminates the need to work with multiple companies. We are responsible for
                        all aspects of your window replacement and installation.
                    </Typography>
                </Box>
            </StyledWhyRbaBox>
        </Grid>
        <Grid xs={12} md={5} mdOffset={1}>
            <StyledWhyRbaBox>
                <Box sx={{px: 5, pt: 2}}>
                    <StyledStepNumber>03</StyledStepNumber>
                </Box>
                <Box sx={{p: 2}}>
                    <Typography variant={'h5'} sx={{mb: 2}}>Long Term Value</Typography>
                    <Typography variant={'body1'}>
                        Our comprehensive, hands-on approach ensures your window and installation quality, beauty, and
                        energy savings last for many years.
                    </Typography>
                </Box>
            </StyledWhyRbaBox>
        </Grid>
        <Grid xs={12} md={5}>
            <StyledWhyRbaBox>
                <Box sx={{px: 5, pt: 2}}>
                    <StyledStepNumber>04</StyledStepNumber>
                </Box>
                <Box sx={{p: 2}}>
                    <Typography variant={'h5'} sx={{mb: 2}}>Unrivaled Beauty</Typography>
                    <Typography variant={'body1'}>
                        Renewal by Andersen offers a wealth of window styles, colors, and grilles to fit any home.
                    </Typography>
                </Box>
            </StyledWhyRbaBox>
        </Grid>
    </Grid>;
};

export default WhyRbaSection;
