import {Box, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import ConfirmationImage from '/src/assets/confirmation-image.jpg';

const DeadEndOutOfTerritory = () : JSX.Element => {
    return (
        <Grid container justifyContent={'center'}>
            <Grid
                xs={12}
                md={6}
                // stretch image bc people don't understand aspect ratios
                sx={{display: 'flex', verticalAlign: 'middle'}}
            >
                <Box
                    component={'img'}
                    alt="Renewal by Anderson"
                    src={ConfirmationImage}
                    width={'100%'}
                />
            </Grid>
            <Grid
                xs={12}
                md={6}
                sx={{textAlign: 'center'}}
            >
                <Stack>
                    <Box sx={{
                        mt: 10,
                        mx: 10,
                        border: '2px solid #6CC14C',
                        p: 10,
                    }}
                    >
                        <Typography variant={'h5'} sx={{textTransform: 'uppercase', fontWeight: '500'}}>
                            We’re sorry, this offer is not available in your area.
                        </Typography>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default DeadEndOutOfTerritory;
