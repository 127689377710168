import {LinearProgress} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DiscoverBeauty from '@/components/DiscoverBeauty';
import FibrexBenefitsSection from '@/components/FibrexBenefitsSection';
import FrameColors from '@/components/FrameColors';
import HeroSection from '@/components/HeroSection';
import OurProcess from '@/components/OurProcess';
import Quiz from '@/components/Quiz';
import Testimonials from '@/components/Testimonials';
import WhyRbaSection from '@/components/WhyRbaSection';
import WindowDoorOptions from '@/components/WindowDoorOptions';
import WindowMaterials from '@/components/WindowMaterials';
import {useLeadTokenContext} from '@/contexts/LeadTokenContext';
import type {LeadToken} from '@/types/lead-token';

const LandingDisplay = ({leadToken} : {leadToken : LeadToken}) : JSX.Element => {
    return (
        <Grid container>
            <HeroSection leadToken={leadToken}/>
            <Grid container xs={12} id={'quiz-container'}>
                <Quiz leadToken={leadToken}/>
            </Grid>
            <FibrexBenefitsSection leadToken={leadToken}/>
            <Grid container xs={12} sx={{justifyContent: 'center'}}>
                <WindowMaterials leadToken={leadToken}/>
            </Grid>
            <DiscoverBeauty leadToken={leadToken}/>
            <Testimonials leadToken={leadToken}/>
            <OurProcess leadToken={leadToken}/>
            <WhyRbaSection leadToken={leadToken}/>
            <WindowDoorOptions leadToken={leadToken}/>
            <FrameColors leadToken={leadToken}/>
            <Grid container xs={12}>
                <Quiz leadToken={leadToken}/>
            </Grid>
        </Grid>
    );
};

const Landing = () : JSX.Element => {
    const {leadToken} = useLeadTokenContext();

    return leadToken ? <LandingDisplay leadToken={leadToken}/> : <LinearProgress/>;
};

export default Landing;
