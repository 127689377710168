import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import {mapRawToLeadToken} from '@/mappers/lead-token';
import type {JsonApiDocument} from '@/types/json-api';
import type {LeadToken, RawLeadToken} from '@/types/lead-token';
import {apiUrl} from '@/utils/api';

export const useLeadTokenQuery = (leadTokenId : string | undefined) : UseQueryResult<LeadToken> => {
    const navigate = useNavigate();
    return useQuery({
        enabled: !!leadTokenId,
        queryKey: ['lead-token', leadTokenId],
        queryFn: async ({signal}) => {
            const response = await window.fetch(apiUrl(`/lead-token/${leadTokenId}`).toString(), {
                signal,
            });

            if (!response.ok) {
                navigate('/not-found');
            }

            const raw = await response.json() as JsonApiDocument<RawLeadToken>;
            return mapRawToLeadToken(raw.data);
        },
    });
};
