import type {UseMutationResult} from '@tanstack/react-query';
import {useSnackbar} from 'notistack';
import {useCallback} from 'react';
import {SubmissionError} from '@/utils/errors';

type Result<T> = {
    success : true;
    data : T;
} | {
    success : false;
};

type HandleMutation = <
    TData = unknown,
    TError = unknown,
    TVariables = unknown,
>(
    mutation : UseMutationResult<TData, TError, TVariables>,
    values : NonNullable<UseMutationResult<TData, TError, TVariables>['variables']>,
    handlers ?: Record<string, () => void>,
) => Promise<Result<TData>>;

const useHandleMutation = () : HandleMutation => {
    const {enqueueSnackbar} = useSnackbar();

    return useCallback(async (mutation, values, handlers) => {
        try {
            return {
                success: true,
                data: await mutation.mutateAsync(values),
            };
        } catch (error) {
            console.error(error);

            if (error instanceof SubmissionError) {
                if (handlers && error.code && error.code in handlers) {
                    handlers[error.code]();
                    return {success: false};
                }

                enqueueSnackbar(error.message, {variant: 'error'});
                return {success: false};
            }

            enqueueSnackbar('Failed to perform request', {variant: 'error'});
            return {success: false};
        }
    }, [enqueueSnackbar]);
};

export default useHandleMutation;
