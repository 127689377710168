import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import type {ReactElement} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import {z} from 'zod';
import EnergyStarLogo from '@/assets/footer/energy_star_logo.png';
import NfrcLogo from '@/assets/footer/nfrc_logo.jpg';
import RbaAcclaimLogo from '@/assets/footer/rba_acclaim_logo.jpg';
import ScsRecycledLogo from '@/assets/footer/scs_recycled_logo.jpg';
import UsaBuiltLogo from '@/assets/footer/usa_built_logo.jpg';
import WarrantyLogo from '@/assets/footer/warranty_logo.png';
import {useOfferQuery} from '@/queries/offer';
import type {LeadToken} from '@/types/lead-token';

const Disclaimer = () : JSX.Element => {
    return <Grid sx={{justifyContent: 'center'}}>
        <Typography variant={'body2'}>
            <sup>†</sup>It is the only warranty among top selling window companies that meets all of the following
            requirements: easy to understand terms, unrestricted transferability, installation coverage, labor
            coverage, geographically unrestricted, coverage for exterior color, insect screens and hardware, and
            no maintenance requirement. Visit renewalbyandersen.com/nationsbest for details.
        </Typography>
        <Typography variant={'body2'} sx={{mt: 1}}>
            <sup>1</sup>See Renewal by Andersen® Products and Installation Transferable Limited Warranty for details.
            ©2024 Andersen Corporation. All rights reserved. ©2024 Lead Surge LLC.
        </Typography>
        <Typography variant={'body2'} sx={{mt: 1}}>
            <sup>2</sup>'ENERGY STAR' is a registered trademark of the U.S. Environmental Protection Agency.
        </Typography>
        <Typography variant={'body2'} sx={{mt: 1}}>
            <sup>3</sup>Values are based on comparison of Renewal by Andersen® double-hung window U-Factor to the
            U-Factor for clear dual pane glass non-metal frame default values from the 2006, 2009, 2012, 2015, and
            2018 International Energy Conservation Code "Glazed Fenestration" Default Tables.
        </Typography>
        <Typography variant={'body2'} sx={{mt: 1}}>
            <sup>*</sup>Based on Medallia homeowner satisfaction surveys of Renewal by Andersen customers January
            2019-December 2023. Average scores between 9 and 10 using Net Promoter Score methodology on all survey
            questions after installation project completed.
        </Typography>
        <Typography variant={'body2'} sx={{mt: 1}}>
            <sup>‡</sup>Review aggregator survey of the most 5-star reviews earned in 2023 by leading local window and
            door replacement companies. Reputation.com, January 2024.
        </Typography>
    </Grid>;
};

const CertificationLogos = () : JSX.Element => {
    const logoHeight = '100px';
    return <Grid container sx={{justifyContent: 'center'}}>
        <Grid container xs={12} sx={{display: 'flex', justifyContent: 'center', padding: 1}}>
            <Box
                component={'img'}
                alt="Energy Star"
                src={EnergyStarLogo}
                sx={{maxHeight: logoHeight, margin: '0 20px 20px 0'}}
            />
            <Box
                component={'img'}
                alt="Warranty"
                src={WarrantyLogo}
                sx={{maxHeight: logoHeight, marginRight: '20px'}}
            />
            <Box
                component={'img'}
                alt="National Fenestration Rating Council"
                src={NfrcLogo}
                sx={{maxHeight: logoHeight}}
            />
            <Box
                component={'img'}
                alt="Acclaim Replacement Windows exclusively from Renewal By Anderson"
                src={RbaAcclaimLogo}
                sx={{maxHeight: logoHeight}}
            />
            <Box
                component={'img'}
                alt="Certified Recycled Content SCS Global Services"
                src={ScsRecycledLogo}
                sx={{maxHeight: logoHeight, mr: '20px'}}
            />
            <Box
                component={'img'}
                alt="Custom built in the USA using US and imported parts"
                src={UsaBuiltLogo}
                sx={{maxHeight: logoHeight}}
            />
        </Grid>
    </Grid>;
};

const Footer = ({leadToken} : {leadToken : LeadToken}) : ReactElement => {
    const offerQuery = useOfferQuery(leadToken.pageId);
    const {pathname} = useLocation();

    // kind of hacky way to tell if we're on the homepage...
    const isHomePage = z.string().uuid().safeParse(pathname.substring(1)).success;

    return <Grid container>
        <Grid xs={12}>
            <CertificationLogos/>
        </Grid>
        <Grid xs={12}>
            {offerQuery.data && isHomePage
                ? <Grid container xs={12} justifyContent={'center'}>
                    <Grid justifyContent={'center'} xs={10} sx={{padding: 3}}>
                        <Typography
                            variant={'body2'}
                            className='dangerous'
                            dangerouslySetInnerHTML={{__html: offerQuery.data.offerDisclaimer ?? ''}}
                        />
                    </Grid>
                </Grid>
                : <></>
            }
        </Grid>
        <Grid
            container
            spacing={2}
            sx={{
                backgroundColor: '#001722',
                color: '#fff',
                alignItems: 'center',
                flexWrap: 'wrap-reverse',
                minHeight: 150,
                mx: 0,
            }}>
            <Grid xs={12} md={6} mdOffset={1}>
                <Disclaimer/>
            </Grid>
            <Grid xs={12} md={5}>
                <>
                    <Link to={'https://www.renewalbyandersen.com/about/terms-of-use'} style={{textDecoration: 'none', color: '#fff'}}>
                        Terms of Service</Link> | <HashLink
                        to={`/privacy/${leadToken.id}`}
                        style={{textDecoration: 'none', color: '#fff'}}
                    >
                    Privacy Policy</HashLink> | <HashLink
                        to={`/privacy/${leadToken.id}#privacy-policy`}
                        style={{textDecoration: 'none', color: '#fff'}}>
                    Privacy Notice for CA Residents</HashLink>
                </>
            </Grid>
        </Grid>
    </Grid>;
};

export default Footer;
