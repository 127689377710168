import type {SxProps} from '@mui/material';
import {Box} from '@mui/material';
import type React from 'react';

type Props = {
    onClick : () => void;
    sx : SxProps;
    children : React.ReactElement;
};

const CarouselButton = ({onClick, sx, children} : Props) : JSX.Element => {
    return (
        <Box
            onClick={onClick}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80px',
                width: '80px',
                backgroundColor: '#fff',
                borderRadius: '40px',
                position: 'absolute',
                opacity: '.7',
                cursor: 'pointer',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

export default CarouselButton;
