import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import type {JsonApiDocument} from '@/types/json-api';
import {apiUrl} from '@/utils/api';

export class LsrcFetchError extends Error {}

export const useLsrcPhoneNumberQuery = (sourceId : string | null) : UseQueryResult<string | null> => {
    return useQuery({
        queryKey: ['lsrc-phone-number', sourceId],
        queryFn: async ({signal}) => {
            const response = await window.fetch(apiUrl(`/lsrc/phone-number/${sourceId}`).toString(), {
                signal,
            });

            if (!response.ok) {
                throw new LsrcFetchError(`Unable to fetch phone number for sourceId ${sourceId}`);
            }

            const raw = await response.json() as JsonApiDocument<{data : string | null}>;
            return raw.data;
        },
    });
};
