import {Box, Paper, useTheme} from '@mui/material';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useRef, useState} from 'react';
import TagManager from 'react-gtm-module';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';
import Awning1 from 'src/assets/windows-and-doors/awning-1.png';
import Awning2 from 'src/assets/windows-and-doors/awning-2.png';
import Awning3 from 'src/assets/windows-and-doors/awning-3.png';
import Bay1 from 'src/assets/windows-and-doors/bay-1.png';
import Bay2 from 'src/assets/windows-and-doors/bay-2.jpg';
import Bay3 from 'src/assets/windows-and-doors/bay-3.png';
import Casement1 from 'src/assets/windows-and-doors/casement-1.png';
import Casement2 from 'src/assets/windows-and-doors/casement-2.jpg';
import Casement3 from 'src/assets/windows-and-doors/casement-3.png';
import DoubleHung1 from 'src/assets/windows-and-doors/double-hung-1.png';
import DoubleHung2 from 'src/assets/windows-and-doors/double-hung-2.png';
import DoubleHung3 from 'src/assets/windows-and-doors/double-hung-3.png';
import Ensemble1 from 'src/assets/windows-and-doors/ensemble-1.png';
import Ensemble2 from 'src/assets/windows-and-doors/ensemble-2.png';
import Ensemble3 from 'src/assets/windows-and-doors/ensemble-3.png';
import Patio1 from 'src/assets/windows-and-doors/patio-1.png';
import Patio2 from 'src/assets/windows-and-doors/patio-2.png';
import Patio3 from 'src/assets/windows-and-doors/patio-3.png';
import AwningThumbnail from 'src/assets/windows-and-doors/productcard-awning.png';
import CasementThumbnail from 'src/assets/windows-and-doors/productcard-casement.png';
import PatioThumbnail from 'src/assets/windows-and-doors/productcard-contemporarysliding-300x288.webp';
import DoubleHungThumbnail from 'src/assets/windows-and-doors/productcard-doublehung.png';
import EnsembleThumbnail from 'src/assets/windows-and-doors/productcard-hingedfrenchwoodpatio-271x300.webp';
import PictureThumbnail from 'src/assets/windows-and-doors/productcard-picture.png';
import SlidingThumbnail from 'src/assets/windows-and-doors/productcard-sliding.png';
import SpecialtyThumbnail from 'src/assets/windows-and-doors/productcard-specialty.png';
import Sliding1 from 'src/assets/windows-and-doors/sliding-1.png';
import Sliding2 from 'src/assets/windows-and-doors/sliding-2.png';
import Sliding3 from 'src/assets/windows-and-doors/sliding-3.png';
import Specialty1 from 'src/assets/windows-and-doors/specialty-1.png';
import Specialty2 from 'src/assets/windows-and-doors/specialty-2.png';
import Specialty3 from 'src/assets/windows-and-doors/specialty-3.png';

const optionsArray = [
    {
        headline: 'Double-Hung Windows',
        subtext: 'Double-hung windows offer a traditional look with a wide range of design options.',
        thumbnail: DoubleHungThumbnail,
        images: [DoubleHung1, DoubleHung2, DoubleHung3],
    },
    {
        headline: 'Casement Windows',
        subtext: 'Casement windows are our most energy-efficient, ventilating window style.',
        thumbnail: CasementThumbnail,
        images: [Casement1, Casement2, Casement3],
    },
    {
        headline: 'Picture & Bay Windows',
        subtext: 'Our picture and combination windows are the ideal solution for showcasing a beautiful view.',
        thumbnail: PictureThumbnail,
        images: [Bay1, Bay2, Bay3],
    },
    {
        headline: 'Sliding Windows',
        subtext: 'Maximize your view with contemporary Renewal by Andersen® sliding windows.',
        thumbnail: SlidingThumbnail,
        images: [Sliding1, Sliding2, Sliding3],
    },
    {
        headline: 'Specialty Windows',
        subtext: 'Specialty window styles include round, arch, circle, circle top, pentagon, arched and more.',
        thumbnail: SpecialtyThumbnail,
        images: [Specialty1, Specialty2, Specialty3],
    },
    {
        headline: 'Awning Windows',
        subtext: 'Replacement awning windows are easy to open, great for hard-to-reach areas.',
        thumbnail: AwningThumbnail,
        images: [Awning1, Awning2, Awning3],
    },
    {
        headline: 'Patio Doors',
        subtext: '',
        thumbnail: PatioThumbnail,
        images: [Patio1, Patio2, Patio3],
    },
    {
        headline: 'Entry Doors',
        subtext: '',
        thumbnail: EnsembleThumbnail,
        images: [Ensemble1, Ensemble2, Ensemble3],
    },
];

const StyledOptionBox = styled(Box)(() => ({
    border: '2px solid #50565a',
    width: '11rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: '15px',
    marginLeft: '5px',
}));

type OptionsProps = {option : {headline : string; subtext : string; thumbnail : string; images : string[]}};
type OptionExampleBoxProps = OptionsProps & {
    isMobile : boolean;
};

const OptionExamplesBox = ({option, isMobile} : OptionExampleBoxProps) : React.ReactElement => {
    return <Paper sx={{mt: 5}}>
        <Grid container xs={12}>
            <Grid xs={12} sx={{textAlign: 'center'}}>
                <Typography variant={'h4'} sx={{mt: 3}}>{option.headline}</Typography>
            </Grid>
            <Grid xs={12} sx={{textAlign: 'center'}}>
                <Typography variant={'body1'} sx={{my: 5, px: 2}}>{option.subtext}</Typography>
            </Grid>
            <Grid container xs={12} sx={{textAlign: 'center'}}>
                {option.images.map(
                    (image, index) => <Grid
                        xs={12}
                        md={4}
                        key={index}
                        sx={{px: 2, mb: 2}}
                    >
                        <Box
                            component={'img'}
                            alt="Renewal by Anderson"
                            src={image}
                            maxWidth={isMobile ? '25rem' : '30rem'}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    </Paper>;
};

const WindowDoorOptions = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [selectedOption, setSelectedOption] = useState(0);
    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'window_door_options',
                    action: 'scroll',
                },
            });
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'window_door_options'});
        }
    }, [isSectionVisible]);

    return <Grid container xs={12} sx={{justifyContent: 'center', backgroundColor: '#fbfaf7', py: 5}}>
        <Grid xs={12} textAlign={'center'}>
            <Typography variant={'h3'} ref={ref}>
                Explore All The Window and Door Possibilities!
            </Typography>
        </Grid>
        <Grid xs={12} sx={{textAlign: 'center', maxWidth: '800px', mt: 2, mb: 5, px: 2}}>
            <Typography variant={'body1'}>
                Every window and door we make is <u>custom-built for your home</u>. We offer a wide a range of styles,
                colors, hardware and grille options.  Find the perfect match to customize and complete your home’s
                one-of-a-kind design.
            </Typography>
        </Grid>
        <Grid xs={12} sx={{
            mx: isMobile ? 2 : 10,
            overflow: 'scroll',
            display: 'flex',
            justifyContent: 'space-between',
        }}>
            {optionsArray.map((option, index) => <StyledOptionBox
                key={index}
                sx={{
                    px: 5,
                    backgroundColor: index === selectedOption ? '#a1db80' : '#fff',
                }}
                onClick={() => {
                    setSelectedOption(index);
                }}>
                <Box
                    component={'img'}
                    alt="Renewal by Anderson"
                    src={option.thumbnail}
                    height={'40px'}
                />
                <Typography sx={{fontSize: '14px', mt: 1}}>{option.headline}</Typography>
            </StyledOptionBox>)}
        </Grid>
        <Grid container xs={12} justifyContent={'center'}>
            <OptionExamplesBox
                option={optionsArray[selectedOption]}
                isMobile={isMobile}
            />
        </Grid>
    </Grid>;
};

export default WindowDoorOptions;
