import type {UseMutationResult} from '@tanstack/react-query';
import {useQueryClient, useMutation} from '@tanstack/react-query';
import {mapRawToLeadToken} from '@/mappers/lead-token';
import type {JsonApiDocument} from '@/types/json-api';
import type {LeadToken, NewLeadToken, RawLeadToken, Requester} from '@/types/lead-token';
import type {QuizAnswers} from '@/types/quiz';
import {apiUrl} from '@/utils/api';

export const useCreateLeadTokenMutation = () : UseMutationResult<LeadToken, Error, NewLeadToken, never> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values : NewLeadToken) => {
            const response = await window.fetch(apiUrl('/lead-token').toString(), {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to generate LeadToken');
            }

            const raw = await response.json() as JsonApiDocument<RawLeadToken>;
            return mapRawToLeadToken(raw.data);
        },
        onSuccess: leadToken => {
            queryClient.setQueryData(['lead-token', leadToken.id], leadToken);
        },
    });
};

export const usePostQuizAnswersMutation
    = (leadToken : LeadToken) : UseMutationResult<LeadToken, Error, QuizAnswers, never> => {
        const queryClient = useQueryClient();

        return useMutation({
            mutationFn: async (values : QuizAnswers) => {
                const response = await window.fetch(apiUrl('/lead-token/quiz-answers').toString(), {
                    method: 'POST',
                    body: JSON.stringify({
                        leadTokenId: leadToken.id,
                        ...values,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to save quiz answers');
                }

                const raw = await response.json() as JsonApiDocument<RawLeadToken>;
                return mapRawToLeadToken(raw.data);
            },
            onSuccess: leadToken => {
                queryClient.setQueryData(['lead-token', leadToken.id], leadToken);
            },
        });
    };

export const useAddRequesterMutation
    = (leadToken : LeadToken) : UseMutationResult<LeadToken, Error, Requester, never> => {
        const queryClient = useQueryClient();
        return useMutation({
            mutationFn: async (values : Requester) => {
                const response = await window.fetch(apiUrl('/lead-token/requester').toString(), {
                    method: 'POST',
                    body: JSON.stringify({
                        leadTokenId: leadToken.id,
                        ...values,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to generate LeadToken');
                }

                const raw = await response.json() as JsonApiDocument<RawLeadToken>;
                return mapRawToLeadToken(raw.data);
            },
            onSuccess: leadToken => {
                queryClient.setQueryData(['lead-token', leadToken.id], leadToken);
            },
        });
    };
