import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {createTheme, CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {SnackbarProvider} from 'notistack';
import {createRoot} from 'react-dom/client';
import TagManager from 'react-gtm-module';
import App from './App';

const theme = createTheme({
    palette: {
        primary: {
            main: '#001722',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1.5em',
                },
            },
        },
    },
});

const queryClient = new QueryClient();

TagManager.initialize({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    gtmId: import.meta.env.VITE_APP_GTM_ID,
});

const container = document.getElementById('root');

if (!container) {
    throw new Error('Root element missing');
}

const root = createRoot(container);
root.render((
    <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
            <SnackbarProvider>
                <CssBaseline/>
                <App/>
            </SnackbarProvider>
        </QueryClientProvider>
    </ThemeProvider>
));
