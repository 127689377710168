import {Box, ImageList, ImageListItem, Paper, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useRef} from 'react';
import TagManager from 'react-gtm-module';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';
import FrameSampleBlack from 'src/assets/frame-samples/frame-sample-black.png';
import FrameSampleCanvas from 'src/assets/frame-samples/frame-sample-canvas.png';
import FrameSampleCocoaBean from 'src/assets/frame-samples/frame-sample-cocoa-bean.png';
import FrameSampleDarkBronze from 'src/assets/frame-samples/frame-sample-dark-bronze.png';
import FrameSampleForestGreen from 'src/assets/frame-samples/frame-sample-forest-green.png';
import FrameSampleMaple from 'src/assets/frame-samples/frame-sample-maple.png';
import FrameSampleOak from 'src/assets/frame-samples/frame-sample-oak.png';
import FrameSamplePine from 'src/assets/frame-samples/frame-sample-pine.png';
import FrameSampleRedRock from 'src/assets/frame-samples/frame-sample-red-rock.png';
import FrameSampleSandstone from 'src/assets/frame-samples/frame-sample-sandstone.png';
import FrameSampleTerratone from 'src/assets/frame-samples/frame-sample-terratone.png';
import FrameSampleWhite from 'src/assets/frame-samples/frame-sample-white.jpg';

const frameData = [
    {img: FrameSampleWhite, title: 'White'},
    {img: FrameSampleCanvas, title: 'Canvas'},
    {img: FrameSampleSandstone, title: 'Sandtone'},
    {img: FrameSampleTerratone, title: 'Terratone'},
    {img: FrameSampleCocoaBean, title: 'Cocoa Bean'},
    {img: FrameSampleDarkBronze, title: 'Dark Bronze'},
    {img: FrameSampleForestGreen, title: 'Forest Green'},
    {img: FrameSampleRedRock, title: 'Red Rock'},
    {img: FrameSampleBlack, title: 'Black'},
    {img: FrameSamplePine, title: 'Pine'},
    {img: FrameSampleOak, title: 'Oak'},
    {img: FrameSampleMaple, title: 'Maple'},
];

const FrameColors = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'frame_colors',
                    action: 'scroll',
                },
            });
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'frame_colors'});
        }
    }, [isSectionVisible]);

    return <Grid container xs={12} sx={{justifyContent: 'center', backgroundColor: '#eef2f9', pb: 5}}>
        <Grid container xs={12} sx={{justifyContent: 'center', mt: isMobile ? 5 : 10}}>
            <Typography variant={'h4'} ref={ref}>Frame Colors & Finishes</Typography>
        </Grid>
        <Grid container xs={12} sx={{justifyContent: 'center', textAlign: 'center', my: 2}}>
            <Typography>
                Fibrex® material window frames come in a variety of colors. Find the perfect match to complete your
                home’s one-of-a-kind design.
            </Typography>
        </Grid>
        <Grid container xs={12} md={10} sx={{justifyContent: 'center'}}>
            <ImageList cols={isMobile ? 3 : 5} rowHeight={isMobile ? 25 : 164} gap={isMobile ? 10 : 30}>
                {frameData.map(item => (
                    <Paper key={item.title} sx={{
                        padding: isMobile ? 3 : 5,
                        pb: 10,
                        borderRadius: 5,
                    }}><ImageListItem sx={{textAlign: 'center'}}>
                            <Box
                                component={'img'}
                                alt="Renewal by Anderson"
                                src={item.img}
                                maxWidth={isMobile ? '5rem' : '10rem'}
                            />
                            <Typography sx={{
                                fontSize: isMobile ? 12 : 24,
                                fontWeight: 500,
                                pt: isMobile ? 0 : 3,
                            }}>
                                {item.title}
                            </Typography>
                        </ImageListItem></Paper>
                ))}
            </ImageList>
        </Grid>
    </Grid>;
};

export default FrameColors;
