import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {mapRawToLeadToken} from '@/mappers/lead-token';
import type {JsonApiDocument} from '@/types/json-api';
import type {LeadToken, RawLeadToken} from '@/types/lead-token';
import {apiUrl} from '@/utils/api';

export const useZipLookupMutation
    = (leadToken : LeadToken) : UseMutationResult<LeadToken, Error, {zip : string; captchaToken : string}, never> => {
        const queryClient = useQueryClient();

        return useMutation({
            mutationFn: async ({zip, captchaToken} : {zip : string; captchaToken : string}) => {
                const response = await window.fetch(apiUrl('/zip').toString(), {
                    method: 'POST',
                    body: JSON.stringify({
                        leadTokenId: leadToken.id,
                        zip: zip,
                        captchaToken: captchaToken,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('zip lookup failed');
                }

                const raw = await response.json() as JsonApiDocument<RawLeadToken>;
                return mapRawToLeadToken(raw.data);
            },
            onSuccess: async leadToken => {
                await queryClient.setQueryData(['lead-token', leadToken.id], leadToken);
            },
        });
    };
