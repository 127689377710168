import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {mapRawToLeadTokenEvent} from '@/mappers/lead-token-event';
import type {JsonApiDocument} from '@/types/json-api';
import type {LeadTokenEvent, NewLeadTokenEvent, RawLeadTokenEvent} from '@/types/lead-token-event';
import {apiUrl} from '@/utils/api';

export const useCreateLeadTokenEventMutation = () : UseMutationResult<LeadTokenEvent, Error, NewLeadTokenEvent, never> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values : NewLeadTokenEvent) => {
            const response = await window.fetch(apiUrl('/lead-token-event').toString(), {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to generate LeadToken');
            }

            const raw = await response.json() as JsonApiDocument<RawLeadTokenEvent>;
            return mapRawToLeadTokenEvent(raw.data);
        },
        onSuccess: leadTokenEvent => {
            queryClient.setQueryData(['lead-token-event', leadTokenEvent.id], leadTokenEvent);
        },
    });
};
